import { useEffect, useState } from 'react';
import { PubSub, EVENTS, ShopConfig } from '@rc-d2c/context-manager';
import { RcCheckbox } from '@rc-d2c/shared-ui/RcCheckbox';
import { RcSelect } from '@rc-d2c/shared-ui/RcSelect';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';

import styles from '../../styles/common.module.scss';

const AddToCartCard = ({ title, payload, withLegacyApis }: { title: string; withLegacyApis: boolean; payload?: any }) => {
  const [mainItem, setMainItem] = useState(payload?.mainItem || '');
  const [skuId, setSkuId] = useState(payload?.skuId || '');
  const [quantity, setQuantity] = useState(payload?.quantity || 1);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(payload?.isSubscriptionChecked || false);
  const [selectedOption, setSelectedOption] = useState(payload?.periodTypeId);
  const [sku, setSku] = useState(payload?.sku || '');
  const [packMultiplicator, setPackMultiplicator] = useState<number>(payload?.packMultiplicator || 1);

  const frequencies = withLegacyApis
    ? ShopConfig.ShopStore.getState().allDeprecatedFrequencies
    : ShopConfig.ShopStore.getState().frequencies;

  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <form className={styles.form}>
        {withLegacyApis ? (
          <>
            <div>
              <label className={styles.cardLabel} htmlFor="mainItem">
                Product ID
              </label>
              <input type="text" name="mainItem" id="mainItem" value={mainItem} onChange={(e) => setMainItem(e.target.value)} />
            </div>
            <div>
              <label className={styles.cardLabel} htmlFor="skuId">
                Sku ID
              </label>
              <input type="text" name="skuId" id="skuId" value={skuId} onChange={(e) => setSkuId(e.target.value)} />
            </div>
            <div>
              <label className={styles.cardLabel} htmlFor="quantity">
                Quantity
              </label>
              <input type="text" name="quantity" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            </div>
          </>
        ) : (
          <>
            <div>
              <label className={styles.cardLabel} htmlFor="sku">
                SKU
              </label>
              <input type="text" name="sku" id="sku" value={sku} onChange={(e) => setSku(e.target.value)} />
            </div>
            <div>
              <label className={styles.cardLabel} htmlFor="packMultiplicator">
                Pack Multiplicator
              </label>
              <input
                type="number"
                name="packMultiplicator"
                id="packMultiplicator"
                value={packMultiplicator}
                onChange={(e) => setPackMultiplicator(parseInt(e.target.value))}
              />
            </div>
            <div>
              <label className={styles.cardLabel} htmlFor="quantity">
                Quantity
              </label>
              <input type="text" name="quantity" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            </div>
          </>
        )}
      </form>
      <div>
        <RcCheckbox
          isChecked={isSubscriptionChecked}
          onChange={() => setIsSubscriptionChecked(!isSubscriptionChecked)}
          label="Subscritpion"
        />
      </div>
      {isSubscriptionChecked && (
        <RcSelect
          name="frequency"
          label="Frequency"
          options={frequencies.map((frequency) => ({
            value: frequency.id,
            content: frequency.name,
          }))}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          translations={{
            scrollDownAria: 'Scroll to down',
            scrollUpAria: 'Scroll to up',
          }}
        />
      )}
      <RcButton
        onClick={() => {
          PubSub.publish(
            EVENTS.ADD_TO_CART,
            withLegacyApis
              ? {
                  ...payload,
                  mainItem,
                  skuId,
                  ean: '1234567890',
                  quantity,
                  isSubscriptionChecked,
                  periodTypeId: selectedOption,
                }
              : {
                  ...payload,
                  mainItem,
                  quantity,
                  isSubscriptionChecked,
                  // .com sends an empty string in this case
                  periodTypeId: selectedOption || '',
                  sku,
                  packMultiplicator,
                  virtualSku: `${sku}_${packMultiplicator}`,
                },
          );
        }}
        label="Add to cart"
      />
    </div>
  );
};

export default AddToCartCard;
