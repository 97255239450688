import { COUNTRIES } from '@rc-d2c/context-manager';

export const productsByCountryLegacy: Partial<
  Record<
    COUNTRIES,
    {
      title: string;
      payload: {
        mainItem: string;
        skuId: string;
        quantity: number;
        isSubscriptionChecked: boolean;
        periodTypeId?: string;
      };
    }[]
  >
> = {
  [COUNTRIES.NL]: [
    {
      title: 'British Shorthair Kitten',
      payload: {
        mainItem: '2566',
        skuId: '8a7081d4818c62cc0181946541610549',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'Savour exigent',
      payload: {
        mainItem: '2531',
        skuId: '8a7081d4818c62cc0181946513300425',
        quantity: 3,
        isSubscriptionChecked: true,
        periodTypeId: '61708',
      },
    },
    {
      title: 'Dachshund Puppy Dry Dog Food',
      payload: {
        mainItem: '2437',
        sku: '451925',
        packMultiplicator: 1,
        quantity: 1,
        isSubscriptionChecked: false,
      } as any,
    },
  ],
  [COUNTRIES.FR]: [
    {
      title: 'Giant Junior pour Chiot',
      payload: {
        mainItem: '3031',
        skuId: '2c91808576903fd801769046162701f8',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'Maxi Joint Care',
      payload: {
        mainItem: '2390',
        skuId: '2c91808576903fd801769045e8a1015a',
        quantity: 1,
        isSubscriptionChecked: true,
        periodTypeId: '61711',
      },
    },
  ],
  [COUNTRIES.DE]: [
    {
      title: 'STERILISED',
      payload: {
        mainItem: '2537',
        skuId: '8a7080fa8fa0f6af018faad1a001000c',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'STERILISED in Soße',
      payload: {
        mainItem: '4095',
        skuId: '8aca896f90c093950190c5e74be40000',
        quantity: 1,
        isSubscriptionChecked: true,
        periodTypeId: '76478',
      },
    },
    {
      title: 'HAIR & SKIN in Soße',
      payload: {
        mainItem: '4071',
        skuId: '8aca936990ca4c6b0190cb150a9b0001',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
  ],
  [COUNTRIES.GB]: [],
  [COUNTRIES.IT]: [],
  [COUNTRIES.SE]: [
    {
      title: 'Maine Coon Kitten',
      payload: {
        mainItem: '2558',
        skuId: '8a7081137bce7f91017be202ee61023a',
        quantity: 1,
        isSubscriptionChecked: false,
      },
    },
    {
      title: 'Ragdoll Adult',
      payload: {
        mainItem: '2515',
        skuId: '8a7081137bce7f91017be202d59a013e',
        quantity: 1,
        isSubscriptionChecked: true,
        periodTypeId: '61711',
      },
    },
  ],
  [COUNTRIES.TR]: [],
  [COUNTRIES.UK]: [],
  [COUNTRIES.US]: [],
};
